<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
      @change="(val) => $emit('closeModal', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <b-overlay :show="show" rounded="sm" no-fade>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
            <h5 class="mb-0">Sayfa Görselleri</h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>
          <div class="p-1">
            <b-row>
              <b-col cols="12">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-complete="handlerComplated"
                  @vdropzone-sending="sendingEvent"
                >
                </vue-dropzone>
              </b-col>
            </b-row>
            <b-row class="pt-1">
              <b-col cols="12" md="6" lg="6">
                <b-button squared block type="submit" variant="primary" @click="onSubmit">
                  <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  <span class="align-middle">{{ $t('global.ekle') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-button squared block @click="hide">
                  <feather-icon icon="XSquareIcon" class="mr-50" />
                  <span class="align-middle">{{ $t('global.iptal') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2 px-1">
              <draggable v-model="images" handle=".draggable-task-handle" @end="handlerResimSiraChange">
                <transition-group type="transition" name="flip-list" class="row" tag="div">
                  <b-col v-for="item in images" :key="item.k_no" cols="12" sm="12" md="4" lg="4">
                    <div class="grid">
                      <b-img
                        thumbnail
                        fluid
                        rounded
                        class="draggable-task-handle"
                        :class="item.kapak ? 'border-success' : ''"
                        :src="imageFile(item.gorsel)"
                        v-b-tooltip.hover.top="'Sıra değiştirme için sürükleyebilirsiniz.'"
                      />
                      <feather-icon
                        v-if="item.kapak"
                        class="text-success cursor-pointer secilen-kapak"
                        size="24"
                        icon="MonitorIcon"
                        v-b-tooltip.hover.top="'Kapak Resmi'"
                      />
                      <div class="figcaption">
                        <feather-icon
                          class="text-danger cursor-pointer mr-1"
                          size="24"
                          icon="Trash2Icon"
                          @click="handlerImageRemove(item)"
                          v-b-tooltip.hover.top="'Görsel Sil!'"
                        />
                        <feather-icon
                          v-if="!item.kapak"
                          class="text-primary cursor-pointer"
                          size="24"
                          icon="MonitorIcon"
                          @click="sayfaResimKapak(item)"
                          v-b-tooltip.hover.top="'Kapak Resmi Yap.'"
                        />
                      </div>
                    </div>
                  </b-col>
                </transition-group>
              </draggable>
              <!-- <b-col v-for="(img, index) in images" :key="index" md="4" cols="12" class="profile-latest-img">
                <div class="grid">
                  <b-img thumbnail fluid rounded :src="typeof img === 'string' ? img : img.src" />
                  <div class="figcaption">
                    <feather-icon icon="SearchIcon" class="mr-50 cursor-pointer" size="24" @click="imgShow(index)" />
                    <feather-icon
                      class="text-danger cursor-pointer"
                      size="24"
                      icon="Trash2Icon"
                      @click="handlerImageRemove(updateData.k_no, img.title)"
                    />
                  </div>
                </div>
              </b-col> -->
            </b-row>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import Cleave from 'vue-cleave-component';
import draggable from 'vuedraggable';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  components: {
    Cleave,
    vueDropzone: vue2Dropzone,
    draggable,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageFile() {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

      return (img) => `${base_url}uploads/sayfa/thumbnail/${img}`;
    },
  },
  data() {
    return {
      show: false,
      dropzoneOptions: {
        paramName: 'sayfa',
        url:
          process.env.NODE_ENV == 'development'
            ? process.env.VUE_APP_DEV_BASEURL + '/web-yonetim/sayfa-resim-ekle'
            : process.env.VUE_APP_PRO_BASEURL + '/web-yonetim/sayfa-resim-ekle',
        method: 'POST',
        thumbnailWidth: 150,
        maxFilesize: 2,
        parallelUploads: 3,
        maxFile: 10,
        addRemoveLinks: true,
        // uploadMultiple: false,
        autoProcessQueue: false,
        headers: { Authorization: 'Bearer: ' + localStorage.getItem('accessToken') },
      },
      images: [],
      visible: false,
      index: null,
    };
  },
  methods: {
    async handlerComplated(res) {
      if (res.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Bilgi`,
            icon: 'CheckSquareIcon',
            variant: 'success',
            text: 'Ekleme Başarılı.',
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Hata`,
            icon: 'CheckSquareIcon',
            variant: 'success',
            text: 'Hata Oluştu.',
          },
        });
      }
      this.$emit('refreshData');
      this.show = false;
      this.$refs.myVueDropzone.removeAllFiles();
      this.$emit('closeModal', false);
    },
    sendingEvent(files, xhr, formData) {
      formData.append('_id', this.updateData._id);
    },

    handlerImagePush(val) {
      this.images = [];
      if (val.length > 0) {
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
        val.forEach((element) => {
          console.log(element);
          this.images.push({
            src: `${base_url}uploads/sayfa/thumbnail/${element.gorsel}`,
            title: element,
          });
        });
      }
    },

    handlerImageRemove(item) {
      this.$store.dispatch('sayfaResimSil', { ...item, sayfa_k_no: this.updateData.k_no }).then((res) => {
        if (res.data.success == true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bilgi`,
              icon: 'CheckSquareIcon',
              variant: 'danger',
              text: 'Silme Başarılı.',
            },
          });
          this.images = res.data.data.gorseller;
        }
      });
    },
    imgShow(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },

    sayfaResimKapak(item) {
      this.$store.dispatch('sayfaResimKapak', { ...item, sayfa_k_no: this.updateData.k_no }).then((res) => {
        if (res.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bilgi`,
              icon: 'Trash2Icon',
              variant: 'success',
              text: `Kapak resmi olarak ayarlandı.`,
            },
          });
          this.images = res.data.data.gorseller;
        }
      });
    },
    handlerResimSiraChange() {
      this.$store
        .dispatch('sayfaResimSiraGuncelle', { k_no: this.updateData.k_no, gorseller: this.images })
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Sira Güncelleme Başarılı.',
              },
            });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    onSubmit() {
      this.show = true;
      this.$refs.myVueDropzone.processQueue();
    },
  },
  watch: {
    updateData(val) {
      if (val) {
        this.images = val.gorseller;
      }
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  border: 1px solid #ebe9f0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.secilen-kapak {
  position: absolute !important;
  top: 8px;
  right: 20px;
}
.grid {
  .figcaption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    width: 100%;
    left: 0;
    right: 0;
  }
  &:hover {
    position: relative;
    background-color: rgba($color: #000000, $alpha: 1);
    img {
      opacity: 0.4;
    }
    .figcaption {
      opacity: 9;
    }
  }
}
</style>

<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="8">
          <b-card-normal title="Sayfa Bilgi" :showLoading="show">
            <template v-slot:body>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Başlık">
                      <b-form-input
                        class="rounded-0"
                        ref="baslik"
                        v-model="form.icerik[defaultDil].baslik"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Ön Açıklama" :rules="{ required: true, max: 160 }" v-slot="validationContext">
                    <b-form-group
                      :label="`Ön Açıklama ${onAciklamaLenght} / 160`"
                      description="Ön Açıklama 160 karakteri geçmemelidir"
                    >
                      <b-form-textarea
                        class="rounded-0"
                        ref="on_aciklama"
                        v-model="form.icerik[defaultDil].on_aciklama"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                        @input="handlerOnAcklamaChange"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="">
                  <validation-provider name="Açıklama" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Açıklama" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                      <quill-editor
                        id="aciklama"
                        v-model="form.icerik[defaultDil].aciklama"
                        :options="snowOption"
                        style="height: 350px"
                        class="pb-5 rounded-0"
                        :class="{ 'is-invalid': !!validationContext.errors[0] }"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Video">
                    <b-form-input class="rounded-0" ref="video" v-model="form.video" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Taglar">
                    <b-form-tags v-model="form.taglar" input-id="tags-basic" class="mb-2 rounded-0" />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </b-card-normal>
        </b-col>
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12">
              <b-card-normal title="İşlemler" :showLoading="show">
                <template v-slot:body>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label-cols="5" label="Dil">
                        <v-select
                          v-model="defaultDil"
                          :options="diller"
                          :reduce="(diller) => diller.lang"
                          label="title"
                          :clearable="false"
                          class="invoice-filter-select d-block"
                          @input="handlerGetDilData($event)"
                          :disabled="!form._id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label-cols="5" label="Yayın Durumu">
                        <v-select
                          v-model="form.statu"
                          :options="durumlar"
                          :reduce="(durum) => durum.id"
                          label="title"
                          :clearable="false"
                          class="invoice-filter-select d-block"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label-cols="5" label="Anasayfa Gösterim">
                        <v-select
                          v-model="form.anasayfa"
                          :options="durumlar"
                          :reduce="(durum) => durum.id"
                          label="title"
                          :clearable="false"
                          class="invoice-filter-select d-block"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label-cols="5" label="Kategoriler">
                        <v-select
                          v-model="form.kategori_k_no"
                          :options="kategoriler"
                          :reduce="(kategori) => kategori.k_no"
                          label="baslik"
                          :clearable="true"
                          class="invoice-filter-select d-block"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button variant="primary" type="submit" block class="rounded-0 mr-1">
                        <feather-icon icon="CheckSquareIcon" class="mr-50" />
                        <span class="align-middle">{{ form.k_no == null ? 'Kaydet' : 'Güncelle' }}</span>
                      </b-button>
                      <b-button v-if="form.k_no" variant="warning" block class="rounded-0" @click="handlerAddImage(form.k_no)">
                        <feather-icon icon="ImageIcon" class="mr-50" />
                        Görseller
                      </b-button>
                      <b-button v-if="form.k_no" variant="success" block class="rounded-0" @click="handlerNewPage()">
                        <feather-icon icon="ImageIcon" class="mr-50" />
                        Yeni Sayfa Ekle
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </b-card-normal>
            </b-col>
            <b-col cols="12">
              <b-card-normal title="Banner ( 1920 x 280 )" :showLoading="show">
                <template v-slot:body>
                  <b-row>
                    <b-col cols="12">
                      <b-form-file
                        v-model="form.banner"
                        placeholder="Bir dosya seçin veya buraya bırakın..."
                        drop-placeholder="Drop file here..."
                        @change="onBannerFileChange"
                      />
                    </b-col>
                    <b-col cols="12">
                      <b-img v-if="newBannerSelect" thumbnail fluid rounded :src="banner_url" height="100" />
                      <div v-else-if="form.banner" class="grid">
                        <b-img thumbnail fluid rounded :src="imageBannerFile" />
                        <div class="figcaption">
                          <feather-icon
                            class="text-danger cursor-pointer"
                            size="24"
                            icon="Trash2Icon"
                            v-b-tooltip.hover.top="'Görsel Sil!'"
                            @click="handlerBannerRemove(form)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </b-card-normal>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <sayfa-gorseller :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </validation-observer>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import SayfaGorseller from './sayfaGorseller.vue';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';

import { ref, reactive, watch, onMounted, nextTick, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import Swal from 'sweetalert2';
import { useRouter } from '@core/utils/utils';

export default {
  components: {
    vSelect,
    BCardNormal,
    quillEditor,
    SayfaGorseller,
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route } = useRouter();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.show = ref(false);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.snowOption = ref({
      theme: 'snow',
      placeholder: 'Açıklama Yazınız...',
    });
    expo.newBannerSelect = ref(false);
    expo.banner_url = ref(null);
    expo.kategoriler = ref([]);
    expo.durumlar = ref([
      { id: true, title: 'Evet' },
      { id: false, title: 'Hayır' },
    ]);

    expo.onAciklamaLenght = ref(0);

    expo.form = ref({
      _id: null,
      k_no: null,
      icerik: {},
      video: null,
      statu: true,
      anasayfa: false,
      kategori_k_no: null,
      banner: null,
      taglar: [],
    });

    expo.handlerOnAcklamaChange = (event) => {
      expo.onAciklamaLenght.value = event.length > 0 ? event.length : 0;
    };

    const createIcerikDilUret = () => {
      expo.diller.value.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = { baslik: null, on_aciklama: null, aciklama: null };
      });
    };

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('kategoriListele');
      const kat = await store.getters.getKategoriler;

      kat.forEach((el) => {
        expo.kategoriler.value.push({ k_no: el.k_no, baslik: el.icerik[expo.defaultDil.value].baslik });
      });
      expo.show.value = false;
    };
    handlerGetData();
    createIcerikDilUret();
    onMounted(async () => {
      const { route } = useRouter();
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        const sayfalar = store.getters.getSayfalar;
        let sayfa = {};
        if (sayfalar.length > 0) {
          sayfa = sayfalar.find((x) => x.k_no === k_no);
          expo.form.value = { ...sayfa };
        } else {
          await store.dispatch('sayfaFindOne', k_no).then((res) => {
            expo.form.value = { ...res };
          });
        }
      }
    });

    expo.onBannerFileChange = (e) => {
      const file = e.target.files[0];
      expo.banner_url.value = URL.createObjectURL(file);
      expo.newBannerSelect.value = true;
    };

    expo.imageBannerFile = computed(() => {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
      if (expo.form.value.banner == null) {
        return '';
      } else {
        return `${base_url}uploads/banner/${expo.form.value.banner}`;
      }
    });

    expo.handlerNewPage = () => {
      expo.form.value = {
        _id: null,
        k_no: null,
        icerik: {},
        video: null,
        statu: true,
        anasayfa: false,
        kategori_k_no: null,
        banner: null,
      };
      createIcerikDilUret();
    };

    expo.handlerAddImage = () => {
      expo.updateData.value = expo.form.value;
      expo.openModal.value = true;
    };

    expo.handlerBannerRemove = () => {
      store.dispatch('sayfaBannerSil', expo.form).then((res) => {
        if (res.data.success == true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bilgi`,
              icon: 'CheckSquareIcon',
              variant: 'danger',
              text: 'Silme Başarılı.',
            },
          });
          this.handlerGetDilData(this.form.dil);
        }
      });
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = expo.form.value;
      Object.keys(forms).map(function (key) {
        if (key == 'icerik') {
          formData.append(key, JSON.stringify(forms[key]));
        } else {
          formData.append(key, forms[key]);
        }
      });
      const base_url = expo.form.value.k_no == null ? 'sayfaEkle' : 'sayfaGuncelle';
      await store
        .dispatch(base_url, formData)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.form.value._id = res.data.data._id;
            expo.form.value.k_no = res.data.data.k_no;
            expo.show.value = false;
          }
        })
        .catch((err) => {
          console.log(err.data.success);
          expo.show.value = false;
          if (err.data.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    expo.handlerRefreshData = () => {
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        store.dispatch('sayfaFindOne', k_no).then((res) => {
          expo.form.value = { ...res };
        });
      }
    };

    watch(expo.closeModal, async (val) => {
      if (val == false) {
        expo.closeModal.value = false;
        expo.openModal.value = false;
        expo.handlerRefreshData();
      }
    });
    return { ...expo };
  },
};
</script>

<style lang="scss">
.grid {
  .figcaption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    width: 100%;
    left: 0;
    right: 0;
  }
  &:hover {
    position: relative;
    background-color: rgba($color: #000000, $alpha: 1);
    img {
      opacity: 0.4;
    }
    .figcaption {
      opacity: 9;
    }
  }
}

@import '@core/scss/vue/libs/quill.scss';
</style>
